import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import Container from "../components/container";
import Columns from "../components/columns";

export const query = graphql`
  query {
    fakturowanie: file(relativePath: { eq: "fakturowanie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Fakturowanie = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Fakturowanie lub integracja z ERP"
      headline="Dopasuj rozwiązanie do potrzeb"
    >
      <SEO
        title="Fakturowanie lub integracja z ERP"
        description="Fakturowanie lub integracja z ERP w systemie dla agencji reklamowej i marketingowej ✅ Dopasuj rozwiązanie do potrzeb • Zapytaj o bezpłatne demo!"
        keywords={["integracja z erp"]}
      />

      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <p>
                System Empireo nie byłby kompletnym rozwiązaniem dla{" "}
                <strong>agencji reklamowych</strong>, gdyby nie{" "}
                <strong>funkcja wystawiania faktur</strong>. Możesz je tworzyć w
                kartotece kontrahenta, ale również bezpośrednio spod dokumentu
                zamówienia. Istnieje też alternatywne rozwiązanie -{" "}
                <strong>
                  integracja Empireo z Twoim systemem handlowo-magazynowym
                </strong>
                . W takim układzie wystawianie faktur pozostanie po stronie ERP,
                natomiast nasze rozwiązanie będzie automatycznie pobierać te
                dokumenty.
              </p>
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3>Kluczowe możliwości: </h3>
              <p>
                <ul>
                  <li>
                    <strong>Tworzenie faktur spod zamówienia</strong>, co
                    eliminuje konieczność ręcznego przepisywania danych.
                  </li>
                  <li>
                    <strong>Raport nieopłaconych faktur</strong>, czyli
                    zestawienie zaległych dokumentów, które ułatwia proces
                    miękkiej windykacji.
                  </li>
                  <li>
                    <strong>Edycja szablonów faktur</strong>, czyli sposób na
                    stworzenie indywidualnego wyglądu zachowanie spójnej
                    identyfikacji marki.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text-block">
              <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
                images={[data.fakturowanie]}
                alts={[
                  "Fakturowanie lub integracje z systemami ERP w systemie do agencji reklamowej lub marketingowej",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <p>
          <strong>Wystawianie lub importowanie faktur do Empireo</strong> wiąże
          się nie tylko z oszczędnością czasu na ich ręcznym tworzeniu, ale
          również wyszukiwaniu czy też analizowaniu statusu płatności. To także
          brak konieczności pamiętania o tym kto powinien dokonać płatności -
          system Empireo wyświetla{" "}
          <strong>listę klientów, którzy nie opłacili faktur</strong>.
        </p>
      </Container>
    </FunctionPageLayout>
  );
};

export default Fakturowanie;
